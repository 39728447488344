import { computed } from 'vue'
import { isArray } from 'lodash-es'
import { has } from 'lodash-es'
import { Option, OptionShape, StringOrNumberPair } from '@/scripts/types'

/**
 * Normalizes items to an array of objects with value and label properties.
 *
 * Allows passing multiple different types of items to a component that expects an array of `OptionShape` objects.
 */
export const useNormalizedItems = (items: Option[] | StringOrNumberPair[] | object) => {
    return computed<OptionShape[]>(() => {
        const originalItems = (!isArray(items) ? Object.entries(items) : items) as Option[] | StringOrNumberPair[]

        const normalizedItems = originalItems.map((item): OptionShape => {
            if (has(item as object, 'value') || has(item as object, 'id')) {
                return item as OptionShape
            }

            if (isArray(item)) {
                return {
                    value: item[0],
                    label: item[1] || item[0],
                }
            }

            return {
                value: '',
                label: '',
            }
        })

        // Ensure "empty" option appears first
        const emptyOptionIndex = normalizedItems.findIndex(item => item.value === '')

        if (emptyOptionIndex > 0) {
            const [anyoneOption] = normalizedItems.splice(emptyOptionIndex, 1)

            normalizedItems.unshift(anyoneOption)
        }

        return normalizedItems
    })
}
